<template>
  <Head>
    <title>FAQ | M-Changa Africa</title>
    <meta
      name="description"
      content="M-Changa Africa: Find answers to the most common questions about our platform"
    />
    <meta name="author" content="Mobi Changa" />
    <meta property="og:type" content="website" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta property="og:url" :content="this.baseURL + '/faq'" />
    <meta property="og:title" content="FAQ | M-Changa Africa" />
    <meta
      property="og:description"
      content="M-Changa Africa: Find answers to the most common questions about our platform"
    />
    <meta property="fb:app_id" :content="this.facebookID" />
  </Head>
  <div>
    <Breadcrumbs
      src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/top-banner-faq.jpg"
      breadcrumb="Questions"
      title="Questions"
    />

    <section id="content">
      <div class="section nobg nomargin">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="accordion faq-questions-wrap clearfix">
                <dl class="accordion-item">
                  <Disclosure as="div" class="pb-0 mb-0" v-slot="{ open }">
                    <dt class="text-lg">
                      <DisclosureButton
                        class="text-left w-full flex justify-between items-center text-gray-400"
                      >
                        <h3 class="text-secondary mb-0">What Is M-Changa?</h3>
                        <span class="md:ml-6 h-7 flex items-center">
                          <MinusCircleIcon
                            :class="[
                              open ? 'inline-block' : 'hidden',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                          <PlusCircleIcon
                            :class="[
                              open ? 'hidden' : 'inline-block',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                        </span>
                      </DisclosureButton>
                    </dt>
                    <transition name="fade">
                      <DisclosurePanel
                        as="dd"
                        class="md:pr-12 pb-0 mb-0 transform transition-all"
                      >
                        <div class="acc_content clearfix">
                          <p>
                            M-Changa is Africa's largest Online Fundraising
                            Platform. We provide the most Secure, Transparent &
                            Convenient way to raise funds.
                          </p>
                          <p>
                            Our Fundraisers include: Medical, Funeral, Weddings,
                            Churches, Businesses, Education, Projects, Sports,
                            Construction & Disasters.
                          </p>
                          <p>Fundraising campaigns::</p>
                          <ul class="list-disc">
                            <li>
                              Number of fundraisers: {{ numberFundraisers }}
                            </li>
                            <li>Number of supporters: {{ numberDonations }}</li>
                            <li v-if="currency == 'UGX'">
                              Range of fundraiser totals: UGX 7,700 to UGX
                              250,000,000
                            </li>
                            <li v-else-if="currency == 'GHS'">
                              Range of fundraiser totals: GHS 17 to GHS 530,000
                            </li>
                            <li v-else-if="currency == 'TZS'">
                              Range of fundraiser totals: TZS 4,500 to TZS
                              140,000,000
                            </li>
                            <li v-else>
                              Range of fundraiser totals: KES 250 to KES
                              8,500,000
                            </li>
                          </ul>
                        </div>
                      </DisclosurePanel>
                    </transition>
                  </Disclosure>
                </dl>
                <dl class="accordion-item">
                  <Disclosure as="div" v-slot="{ open }">
                    <dt class="text-lg">
                      <DisclosureButton
                        class="text-left w-full flex justify-between items-center text-gray-400"
                      >
                        <h3 class="text-secondary mb-0">
                          How Does M-Changa Work?
                        </h3>
                        <span class="md:ml-6 h-7 flex items-center">
                          <MinusCircleIcon
                            :class="[
                              open ? 'inline-block' : 'hidden',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                          <PlusCircleIcon
                            :class="[
                              open ? 'hidden' : 'inline-block',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                        </span>
                      </DisclosureButton>
                    </dt>
                    <transition name="fade">
                      <DisclosurePanel
                        as="dd"
                        class="md:pr-12 mb-0 transform transition-all"
                      >
                        <div class="acc_content clearfix">
                          <h4>For Fundraisers</h4>
                          <p>
                            M-Changa provides a platform for Transparent, Secure
                            & Convenient fundraising.
                          </p>
                          <ol class="list-decimal">
                            <li>
                              Fundraisers Register free on M-Changa at
                              <a :href="this.baseURL + '/signup'">Sign-UP</a>
                              (fundraisers without internet access can register
                              by dialing *483*57# - KENYA ONLY)
                            </li>
                            <li>
                              M-Changa provides a unique fundraising Sharepage,
                              enabling Fundraisers to:
                              <ul class="list-disc">
                                <li>
                                  Customize with their fundraiser with a Story,
                                  Pictures, Videos, Synonym & Targets
                                </li>
                                <li>
                                  Share their fundraiser using Facebook,
                                  Twitter, WhatsApp, SMS etc.
                                </li>
                                <li>
                                  Collect funds from Safaricom Paybill, Equitel,
                                  Airtel, T-Kash (KE), Tigo, Airtel MTN (GH),
                                  Airtel, MTN (UG) Visa, NIBBS(NG), MasterCard &
                                  PayPal (Worldwide)
                                </li>
                              </ul>
                            </li>
                            <li>
                              M-Changa secures fundraisers through; Human
                              Expertise, Fundraiser Verification, Treasurer
                              Controlled & Secure Technology
                            </li>
                            <li>
                              Fundraisers can withdraw their funds anytime,
                              provided the account is Verified and Treasurers
                              have approved
                            </li>
                          </ol>
                          <h4>For Supporters</h4>
                          <ol class="list-decimal">
                            <li>
                              Contributor gets invited to the fundraiser via
                              Facebook, WhatsApp, SMS etc.
                            </li>
                            <li>
                              Contributor visits the fundraiser Sharepage where
                              they can:
                              <ul class="list-disc">
                                <li>
                                  Learn more about the fundraiser (story,
                                  images, video, targets & progress)
                                </li>
                                <li>
                                  Make a Mobile Money donation (M-PESA, Equitel,
                                  Airtel, T-Kash (KE), Tigo, Airtel MTN (GH),
                                  Airtel, MTN (UG)), NIBBS(NG)
                                </li>
                                <li>
                                  Make a Credit Card donation (Visa, MasterCard
                                  or PayPal)
                                </li>
                                <li>
                                  Share the campaign using embedded Social Media
                                  widgets
                                </li>
                              </ul>
                            </li>
                            <li>
                              Automatic SMS / emails are sent to thank
                              supporters, confirm their donation and update them
                              on fundraiser progress. These messages are often
                              shared in WhatsApp to encourage further
                              contributions.
                            </li>
                          </ol>
                          <p>
                            More on Online Fundraisers
                            <a
                              title="online fundraisers"
                              :href="this.baseURL + '/online-fundraiser'"
                              >here</a
                            >
                          </p>
                          <p>
                            More on Mobile fundraisers
                            <a
                              title="USSD Fundraisers"
                              :href="this.baseURL + '/mobile-fundraiser'"
                              >here</a
                            >
                          </p>
                          <p>
                            YouTube Explainer Video
                            <a
                              title="Explainer video"
                              href="https://www.youtube.com/watch?v=0EEh7pHpSCU"
                              target="_blank"
                              >here</a
                            >
                          </p>
                        </div>
                      </DisclosurePanel>
                    </transition>
                  </Disclosure>
                </dl>

                <dl class="accordion-item">
                  <Disclosure as="div" v-slot="{ open }">
                    <dt class="text-lg">
                      <DisclosureButton
                        class="text-left w-full flex justify-between items-center text-gray-400"
                      >
                        <h3 class="text-secondary mb-0">
                          How Do I Start A Fundraiser?
                        </h3>
                        <span class="md:ml-6 h-7 flex items-center">
                          <MinusCircleIcon
                            :class="[
                              open ? 'inline-block' : 'hidden',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                          <PlusCircleIcon
                            :class="[
                              open ? 'hidden' : 'inline-block',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                        </span>
                      </DisclosureButton>
                    </dt>
                    <transition name="fade">
                      <DisclosurePanel
                        as="dd"
                        class="md:pr-12 mb-0 transform transition-all"
                      >
                        <div class="acc_content clearfix">
                          <p>
                            Fundraiser registration is quick, easy and free.
                            There are two ways to register:
                          </p>
                          <ol class="list-decimal">
                            <li>
                              To register Online, go here
                              <a :href="this.baseURL + '/register'">Sign-UP</a>
                              and follow the instructions
                            </li>
                            <li>
                              To register using your Mobile, dial *483*57# and
                              follow the instructions (recommended for
                              fundraisers without internet access - Kenya Only)
                            </li>
                          </ol>
                        </div>
                      </DisclosurePanel>
                    </transition>
                  </Disclosure>
                </dl>

                <dl class="accordion-item">
                  <Disclosure as="div" v-slot="{ open }">
                    <dt class="text-lg">
                      <DisclosureButton
                        class="text-left w-full flex justify-between items-center text-gray-400"
                      >
                        <h3 class="text-secondary mb-0">
                          How Safe Is My Money?
                        </h3>
                        <span class="md:ml-6 h-7 flex items-center">
                          <MinusCircleIcon
                            :class="[
                              open ? 'inline-block' : 'hidden',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                          <PlusCircleIcon
                            :class="[
                              open ? 'hidden' : 'inline-block',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                        </span>
                      </DisclosureButton>
                    </dt>
                    <transition name="fade">
                      <DisclosurePanel
                        as="dd"
                        class="md:pr-12 mb-0 transform transition-all"
                      >
                        <div class="acc_content clearfix">
                          <p>
                            The safety of your funds from your supporters is our
                            top priority at M-Changa! That’s why we are trusted
                            by over {{ numberFundraisers }} users and
                            {{ numberDonations }} supporters across markets.
                          </p>
                          <p>
                            The M-Changa platform includes the following safety
                            features:
                          </p>
                          <ul class="list-disc">
                            <li>
                              M-Changa holds all funds in a secure account until
                              withdrawal.
                            </li>
                            <li>
                              All fundraisers must be Verified by our experts
                              before being able to withdraw.
                            </li>
                            <li>
                              Fundraisers nominate up to three treasurers who
                              must approve withdrawals.
                            </li>
                            <li>
                              All fundraising activity is transparent; the
                              amount raised, number of contributors and
                              fundraiser updates are publicly available online.
                            </li>
                            <li>
                              Our Customer Care team are available to listen to
                              any public concerns and follow up appropriately
                            </li>
                            <li>
                              You can withdraw your funds at any time, so long
                              as your account is verified and your treasurers
                              have approved
                            </li>
                            <li>
                              Fundraising campaigns that cannot be verified will
                              have the contributions returned to the supporters
                            </li>
                          </ul>
                        </div>
                      </DisclosurePanel>
                    </transition>
                  </Disclosure>
                </dl>
                <dl class="accordion-item">
                  <Disclosure as="div" v-slot="{ open }">
                    <dt class="text-lg">
                      <DisclosureButton
                        class="text-left w-full flex justify-between items-center text-gray-400"
                      >
                        <h3 class="text-secondary mb-0">
                          How Can I Withdraw My Money?
                        </h3>
                        <span class="md:ml-6 h-7 flex items-center">
                          <MinusCircleIcon
                            :class="[
                              open ? 'inline-block' : 'hidden',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                          <PlusCircleIcon
                            :class="[
                              open ? 'hidden' : 'inline-block',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                        </span>
                      </DisclosureButton>
                    </dt>
                    <transition name="fade">
                      <DisclosurePanel
                        as="dd"
                        class="md:pr-12 mb-0 transform transition-all"
                      >
                        <div class="acc_content clearfix">
                          <p>
                            Once you have raised at least {{ currency }}
                            {{ min_cashout }}, you can withdraw your funds
                            provided the campaign is Verified.
                          </p>
                          <p>
                            Funds can be withdrawn multiple times throughout
                            your fundraiser.
                          </p>
                          <ul class="list-disc">
                            <li>
                              <strong>Online Fundraisers:</strong> Click
                              'Withdraw' while logged into your account and
                              follow the instructions.
                            </li>
                            <li>
                              <strong>Mobile Fundraisers:</strong> Dial
                              *483*57*3# and follow the instructions. (KENYA
                              ONLY)
                            </li>
                          </ul>
                          <p>
                            You can withdraw your balance directly to your
                            Mobile Wallet or via EFT Bank Transfer. Withdrawals
                            by Mobile Money take between 30 minutes to 4 hours
                            for processing from Monday to Saturday from 9AM to
                            8PM, EFT Bank Transfers may take more time depending
                            on the Bank.
                          </p>
                          <p>
                            For withdrawals on Sundays or Public holidays,
                            please contact Customer Care in advance on
                            {{ this.supportPhone }}.
                          </p>
                          <p>
                            * Your fundraiser expiry date does not affect
                            withdrawal.
                            <br />
                            * Withdrawing funds does not affect the progress
                            meter displayed on your Sharepage.
                          </p>
                        </div>
                      </DisclosurePanel>
                    </transition>
                  </Disclosure>
                </dl>
                <dl class="accordion-item">
                  <Disclosure as="div" v-slot="{ open }">
                    <dt class="text-lg">
                      <DisclosureButton
                        class="text-left w-full flex justify-between items-center text-gray-400"
                      >
                        <h3 class="text-secondary mb-0">
                          How Much Does It Cost?
                        </h3>
                        <span class="md:ml-6 h-7 flex items-center">
                          <MinusCircleIcon
                            :class="[
                              open ? 'inline-block' : 'hidden',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                          <PlusCircleIcon
                            :class="[
                              open ? 'hidden' : 'inline-block',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                        </span>
                      </DisclosureButton>
                    </dt>
                    <transition name="fade">
                      <DisclosurePanel
                        as="dd"
                        class="md:pr-12 mb-0 transform transition-all"
                        ><div class="acc_content clearfix">
                          <p>
                            M-Changa charges fees to ensure our platform runs
                            smoothly, fundraisers are verified and supported,
                            and donations are processed securely.<br />
                            You can see a full breakdown on
                            <a
                              :href="this.baseURL + '/fees'"
                              title="M-Changa Fees"
                            >
                              our fees page.
                            </a>
                          </p>
                        </div>
                      </DisclosurePanel>
                    </transition>
                  </Disclosure>
                </dl>
                <dl class="accordion-item">
                  <Disclosure as="div" v-slot="{ open }">
                    <dt class="text-lg">
                      <DisclosureButton
                        class="text-left w-full flex justify-between items-center text-gray-400"
                      >
                        <h3 class="text-secondary mb-0">
                          How Do I Verify My Account?
                        </h3>
                        <span class="md:ml-6 h-7 flex items-center">
                          <MinusCircleIcon
                            :class="[
                              open ? 'inline-block' : 'hidden',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                          <PlusCircleIcon
                            :class="[
                              open ? 'hidden' : 'inline-block',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                        </span>
                      </DisclosureButton>
                    </dt>
                    <transition name="fade">
                      <DisclosurePanel
                        as="dd"
                        class="md:pr-12 mb-0 transform transition-all"
                      >
                        <div class="acc_content clearfix">
                          <p>
                            To ensure funds raised through M-Changa are not
                            misappropriated, we take caution to ensure all
                            fundraising campaigns are Verified before the funds
                            are available for withdrawal.
                          </p>
                          <p>The Verification process includes:</p>
                          <ol class="list-decimal">
                            <li>Identity Check</li>
                            <li>Recorded Phone Survey</li>
                            <li>Supporting Documents</li>
                            <li>Treasurers</li>
                            <li>Customer Care Review</li>
                          </ol>
                          <p>
                            We advise all fundraisers to get Verified as soon as
                            possible to avoid account restrictions such as:
                          </p>
                          <ol class="list-decimal">
                            <li>
                              Being unable to accept Credit Card donations
                            </li>
                            <li>Being unable to withdraw funds</li>
                            <li>Having funds returned to supporters</li>
                          </ol>
                          <p>
                            Fundraiser Verification depends on a number of
                            factors and varies from one fundraiser to another.
                            Our policies are being constantly updated for the
                            safety of fundraiser and supporter funds, please
                            contact our dedicated Customer Care team
                            {{ this.supportPhone }} for further guidance on
                            Verification.
                          </p>
                        </div>
                      </DisclosurePanel>
                    </transition>
                  </Disclosure>
                </dl>
                <dl class="accordion-item">
                  <Disclosure as="div" v-slot="{ open }">
                    <dt class="text-lg">
                      <DisclosureButton
                        class="text-left w-full flex justify-between items-center text-gray-400"
                      >
                        <h3 class="text-secondary mb-0">
                          Will M-Changa Help Me To Raise Funds? (Fundraising
                          Tips)
                        </h3>
                        <span class="md:ml-6 h-7 flex items-center">
                          <MinusCircleIcon
                            :class="[
                              open ? 'inline-block' : 'hidden',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                          <PlusCircleIcon
                            :class="[
                              open ? 'hidden' : 'inline-block',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                        </span>
                      </DisclosureButton>
                    </dt>
                    <transition name="fade">
                      <DisclosurePanel
                        as="dd"
                        class="md:pr-12 mb-0 transform transition-all"
                      >
                        <div class="acc_content clearfix">
                          <p>
                            Our platform was designed to give fundraisers the
                            highest rate of success through convenience,
                            security and transparency. When surveyed, over 80%
                            of fundraisers believed they had raised more because
                            of the platform.
                          </p>
                          <p>
                            Unfortunately, we can’t do your fundraising for you,
                            but we will assist where we can! Here are some tips
                            from M-Changa’s most successful fundraisers:
                          </p>
                          <ul class="list-disc">
                            <li>
                              Map Your Network
                              <ul class="list-disc">
                                <li>
                                  Make a list of people who can help you promote
                                  your campaign
                                </li>
                                <li>
                                  Make a list of people who live locally who
                                  might contribute
                                </li>
                                <li>
                                  Make a list of people who live nationally who
                                  might contribute
                                </li>
                                <li>
                                  Make a list of people who live internationally
                                  who might contribute
                                </li>
                              </ul>
                            </li>
                            <li>
                              Individuals give when they are
                              <strong>ASKED</strong>
                              <ul class="list-disc">
                                <li>
                                  Why do you need the funds? Give them a good
                                  reason to contribute
                                </li>
                                <li>
                                  How will the funds be spent? Clearly explain
                                  exactly how funds will be spent
                                </li>
                                <li>
                                  How much do you need to raise? ASK for a
                                  specific amount
                                </li>
                              </ul>
                            </li>
                            <li>
                              Plan to use multiple communication channels –
                              don't rely on social media alone!
                              <ul class="list-disc">
                                <li>
                                  E.g. Face-to-face, WhatsApp, Facebook,
                                  Twitter, Phone call, SMS etc.
                                </li>
                              </ul>
                            </li>
                            <li>
                              Set SMART goals
                              <ul class="list-disc">
                                <li><strong>S</strong> pecific</li>
                                <li><strong>M</strong> easurable</li>
                                <li><strong>A</strong> ctionable</li>
                                <li><strong>R</strong> ealistic</li>
                                <li><strong>T</strong> ime-Bound</li>
                              </ul>
                            </li>
                          </ul>
                          <p>
                            <strong
                              >E.g. Raising for a kidney operation at Kenyatta
                              Hospital, need to raise KES 350,000 for the
                              operation to be performed on 26th
                              February.</strong
                            >
                          </p>
                          <ul class="list-disc">
                            <li>
                              Personalise your fundraiser page
                              <ul class="list-disc">
                                <li>
                                  Use your story, pictures and videos on your
                                  fundraiser page to show contributors more
                                  about your cause and make them more likely to
                                  give
                                </li>
                              </ul>
                            </li>
                            <li>
                              Consider non-monetary benefits
                              <ul class="list-disc">
                                <li>
                                  Fundraising isn’t just about the money,
                                  sometimes people might not have funds
                                  available to give
                                </li>
                                <li>
                                  Some people might offer their time to help you
                                  raise awareness
                                </li>
                                <li>
                                  Some people might offer in-kind support such
                                  as transport
                                </li>
                              </ul>
                            </li>
                            <li>
                              Make the most of free resources online:
                              <ul class="list-disc">
                                <li>
                                  Take a free online course on fundraising with
                                  our partner Philanthropy University:
                                  <a :href="'https://philanthropyu.org/courses'"
                                    >Philanthropy University</a
                                  >
                                </li>
                                <li>
                                  Hire a freelancer to help with your Social
                                  Media, try
                                  <a href="https://kuhustle.com">kuhustle</a> or
                                  Fiverr.com
                                </li>
                                <li>
                                  Find free, relevant articles and downloads at:
                                  <a href="http://www.nptechforgood.com/"
                                    >Nonprofit Tech</a
                                  >
                                </li>
                              </ul>
                            </li>
                            <li>
                              If you're an organisation or a church, we have
                              some wonderful local & international partners:
                              <ul class="list-disc">
                                <li>
                                  Check out some great organisations in Kenya:
                                  <a href="www.yetu.org">Yetu</a>,
                                  <a href="www.globalgiving.org">GlobalGiving</a
                                  >, <a href="www.kcdy.or.ke">kcdy</a>,
                                  <a href="www.eaag.org">eaag</a> and
                                  <a href="www.kafp.org">kafp</a>
                                </li>
                                <li>
                                  Register for a GlobalGiving Accelerator:
                                  <a href="www.globalgiving.org"
                                    >GlobalGiving</a
                                  >
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </DisclosurePanel>
                    </transition>
                  </Disclosure>
                </dl>
                <dl class="accordion-item">
                  <Disclosure as="div" v-slot="{ open }">
                    <dt class="text-lg">
                      <DisclosureButton
                        class="text-left w-full flex justify-between items-center text-gray-400"
                      >
                        <h3 class="text-secondary mb-0">
                          Refunds and Erroneous Payments
                        </h3>
                        <span class="md:ml-6 h-7 flex items-center">
                          <MinusCircleIcon
                            :class="[
                              open ? 'inline-block' : 'hidden',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                          <PlusCircleIcon
                            :class="[
                              open ? 'hidden' : 'inline-block',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                        </span>
                      </DisclosureButton>
                    </dt>
                    <transition name="fade">
                      <DisclosurePanel
                        as="dd"
                        class="md:pr-12 mb-0 transform transition-all"
                      >
                        <div class="acc_content clearfix">
                          <h4>
                            What happens if my supporter donates to the wrong
                            account number/name?
                          </h4>
                          <p>
                            M-Changa resolves many spelling mistakes
                            automatically. However, where this is not possible,
                            M-Changa endeavors to effect the correction as soon
                            as possible and within 36 hours. If we are not able
                            to effect the correction within 30 days, and the
                            donation is above KES 100 (USD 1), M-Changa will
                            refund the donation. If the donation is below KES100
                            (USD 1) M-Changa will direct the donation to a
                            verified charity on the platform.
                          </p>
                          <h4>Can my supporter request a refund?</h4>
                          <p>
                            A supporter can request a refund any time on
                            M-Changa provided that the funds have not already
                            been withdrawn. M-Changa will alert the fundraiser
                            owner by phone at the time of processing.
                          </p>
                          <h4>
                            Can I mass refund my supporters if my fundraiser
                            stalls?
                          </h4>
                          <p>
                            Refunds to supporters can be done at cost to the
                            fundraiser owner. Refund charges for credit card
                            transactions are USD 20 per transaction if not done
                            within 20 days of the transaction. For mobile money
                            donations, refunds are the KES equivalent of the
                            standard mobile money transfer fee for the amount.
                            To request a mass refund contact customer care.
                          </p>
                          <h4>
                            What happens if M-Changa sends me excess funds in
                            error?
                          </h4>
                          <p>
                            If, due to mistake or any other reason, the
                            fundraising campaign / customer, at the time of
                            withdrawal, receives funds in excess of what the
                            receipted indicated balance was at the time of
                            withdrawal, the fundraising campaign / customer
                            shall repay the overpayment to M-Changa in a lump
                            sum within fourteen (14) days of notice of the
                            amount of overpayment. If the fundraising campaign /
                            customer fails to so repay the overpayment, then
                            without limiting any other remedies available to
                            M-Changa, M-Changa may deduct the amount of the
                            overpayment from any other balances which become
                            payable to the fundraising campaign / customer.
                          </p>
                        </div>
                      </DisclosurePanel>
                    </transition>
                  </Disclosure>
                </dl>
                <dl class="accordion-item">
                  <Disclosure as="div" v-slot="{ open }">
                    <dt class="text-lg">
                      <DisclosureButton
                        class="text-left w-full flex justify-between items-center text-gray-400"
                      >
                        <h3 class="text-secondary mb-0">Other Questions</h3>
                        <span class="md:ml-6 h-7 flex items-center">
                          <MinusCircleIcon
                            :class="[
                              open ? 'inline-block' : 'hidden',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                          <PlusCircleIcon
                            :class="[
                              open ? 'hidden' : 'inline-block',
                              'h-5 md:h-9 w-5 md:w-9 text-secondary',
                            ]"
                            aria-hidden="true"
                          />
                        </span>
                      </DisclosureButton>
                    </dt>
                    <transition name="fade">
                      <DisclosurePanel
                        as="dd"
                        class="md:pr-12 mb-0 transform transition-all"
                      >
                        <div class="acc_content clearfix">
                          <h4>
                            What's the largest amount a donor can contribute to
                            my fundraiser via mobile money?
                          </h4>
                          <p>
                            Each mobile money donation is limited by maximum
                            sending limits per transaction for each network.
                          </p>
                          <h4>
                            What is the maximum donation my fundraiser may
                            receive via Stripe or PayPal?
                          </h4>
                          <p>
                            Large donations are subject to security checks to
                            protect your fundraiser and should not exceed the
                            local currency equivalent of USD $10,000 per
                            transaction, unless through prior agreement with
                            M-Changa.
                          </p>
                        </div>
                      </DisclosurePanel>
                    </transition>
                  </Disclosure>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section nomargin">
        <div class="container">
          <div class="tabs side-tabs tabs-responsive faq-other-parts clearfix">
            <ul class="tab-nav clearfix">
              <li
                class="cursor-pointer"
                v-on:click="toggleTabs(1)"
                v-bind:class="{
                  'bg-transparent text-gray-400': openTab !== 1,
                  'text-green-100 active-tab bg-main': openTab === 1,
                }"
              >
                <span>Our Values</span><i class="icon-line-arrow-right"></i>
              </li>
              <li
                class="cursor-pointer"
                v-on:click="toggleTabs(2)"
                v-bind:class="{
                  'bg-transparent text-gray-400': openTab !== 2,
                  'text-green-100 active-tab bg-main': openTab === 2,
                }"
              >
                <span>Indemnity</span><i class="icon-line-arrow-right"></i>
              </li>
              <li
                class="cursor-pointer"
                v-on:click="toggleTabs(3)"
                v-bind:class="{
                  'bg-transparent text-gray-400': openTab !== 3,
                  'text-green-100 active-tab bg-main': openTab === 3,
                }"
              >
                <span>Prohibited Fundraisers</span
                ><i class="icon-line-arrow-right"></i>
              </li>
              <li
                class="cursor-pointer"
                v-on:click="toggleTabs(4)"
                v-bind:class="{
                  'bg-transparent text-gray-400': openTab !== 4,
                  'text-green-100 active-tab bg-main': openTab === 4,
                }"
              >
                <span>Terms of Use</span><i class="icon-line-arrow-right"></i>
              </li>
              <li
                class="cursor-pointer"
                v-on:click="toggleTabs(5)"
                v-bind:class="{
                  'bg-transparent text-gray-400': openTab !== 5,
                  'text-green-100 bg-main': openTab === 5,
                }"
              >
                <span>Privacy Policy</span><i class="icon-line-arrow-right"></i>
              </li>
            </ul>
            <div class="tab-container">
              <div
                class="tab-content clearfix"
                v-bind:class="{
                  hidden: openTab !== 1,
                  block: openTab === 1,
                }"
              >
                <p>
                  M-Changa's mission is to empower fundraising for Kenyans, by
                  Kenyans. Transparency is extremely important to us and guides
                  us in all activities. You can find details about our policies
                  by clicking on the links below:
                </p>
                <ul class="fancy-list">
                  <li>
                    <a href="/commitment"
                      >Our Commitment to Transparency, Fraud Prevention, and
                      Excellence.</a
                    >
                  </li>
                  <li>
                    <a href="/rights-responsibilities"
                      >Fundraiser & Contributor Bill of Rights.</a
                    >
                  </li>
                  <li>
                    <a href="credit-cards"
                      >Fundraiser Rights and Responsibilities for Credit Card
                      Transactions.</a
                    >
                  </li>
                </ul>
              </div>
              <div
                class="tab-content clearfix"
                v-bind:class="{
                  hidden: openTab !== 2,
                  block: openTab === 2,
                }"
              >
                <p>
                  M-Changa is a content neutral provider of mobile alerts,
                  fundraising applications, and related services. As such,
                  M-Changa does not endorse any product, service, political
                  party, or viewpoint. M-Changa reserves the right to monitor
                  content on its systems and remove any that in its sole opinion
                  M-Changa finds misleading, offensive, or otherwise
                  inappropriate.
                </p>
              </div>
              <div
                class="tab-content clearfix"
                v-bind:class="{
                  hidden: openTab !== 3,
                  block: openTab === 3,
                }"
              >
                <p>
                  Fundraiser owners are not allowed to raise funds for illegal
                  activities, to cause harm to people or property, or to scam
                  others. Fundraiser campaigns must comply with applicable laws
                  and regulations in connection with their campaigns. Fundraiser
                  owners shall not make any false or misleading statements in
                  connection with their campaigns. Fundraiser owners are
                  prohibited from engaging in impersonation, copyright and
                  patent infringement of any kind. Fundraising for political
                  purposes directly related to election events are strictly
                  prohibited.
                </p>
              </div>
              <div
                class="tab-content clearfix"
                v-bind:class="{
                  hidden: openTab !== 4,
                  block: openTab === 4,
                }"
              >
                <p>
                  To read detailed terms and conditions for M-Changa please see
                  our <a :href="'/terms-of-use'">Terms of Use</a>.
                </p>
              </div>
              <div
                class="tab-content clearfix"
                v-bind:class="{
                  hidden: openTab !== 5,
                  block: openTab === 5,
                }"
              >
                <p>
                  Once approved, your fundraiser may automatically qualify to be
                  published on this website. At anytime you may request to have
                  your fundraiser "not listed" or removed from this website by
                  contacting us For more details see the
                  <a href="/privacy-policy">M-Changa privacy policy</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuestionBottom />
    </section>
    <Footer />
  </div>
</template>
<script>
import { Head } from '@vueuse/head';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/vue/outline';
import Breadcrumbs from '../components/Breadcrumbs.vue';
import QuestionBottom from '../components/QuestionBottom.vue';
import Footer from '../components/Footer/index.vue';

export default {
  components: {
    Head,
    Breadcrumbs,
    QuestionBottom,
    Footer,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    PlusCircleIcon,
    MinusCircleIcon,
  },
  data() {
    return {
      openTab: 1,
      changaStats: {},
      trends: {},
      numberFundraisers: 0,
      numberDonations: 0,
      npsScore: 0,
      duration: 1,
      min_cashout: 250,
      currency: 'KES',
      smsCost_small: 2.0,
      smsCost_medium: 1.0,
      smsCost_large: 0.6,
      eftFee: 2500,
    };
  },
  async created() {
    this.homeData();
  },
  methods: {
    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },
    homeData: async function () {
      fetch(process.env.VUE_APP_ROOT_API + `/service/fundraiser/home-stats`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.changaStats = data;
          this.numberFundraisers = data.all_accounts.toLocaleString('en', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
          this.numberDonations = data.all_donations.toLocaleString('en', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
          this.npsScore = data.nps_score;
          const currencyConfig = data['currencyConfig'];
          const int_codes = this.allowed_country_codes;
          if (int_codes.includes(currencyConfig.phone_code.toString())) {
            this.currency = currencyConfig.currency_code;
            this.min_cashout = currencyConfig.min_cashout;
            this.smsCost_small = currencyConfig.sms_cost_a;
            this.smsCost_medium = currencyConfig.sms_cost_b;
            this.smsCost_large = currencyConfig.sms_cost_c;
          }
        });
      fetch(process.env.VUE_APP_ROOT_API + `/service/fundraiser/trending`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.trends = data;
        });
    },
  },
};
</script>
